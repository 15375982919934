import React from 'react'
import { useTranslation } from 'react-i18next'

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

export const FallBack = () => {
  const { t } = useTranslation()

  const handleRefresh = () => {
    console.log('Refresh Triggered')
    window.location.reload()
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ my: 10 }}
    >
      <ErrorOutlineOutlinedIcon
        color="primary"
        sx={{ width: 50, height: 50, mb: 2 }}
      />
      <Typography variant="h6">{t('Oops! Something went wrong.')}</Typography>
      <Typography variant="caption" textAlign={'center'}>
        {t('Please refresh the page or')} {''}
        <Link color="secondary.light" href="mailto:support@intelibly.com">
          {t('contact support')}
        </Link>{' '}
        {t('if the issue persists')}.
      </Typography>
      <Button
        aria-label="Refresh"
        variant="outlined"
        color="primary"
        onClick={handleRefresh}
        sx={{ mt: 4 }}
      >
        {t('Refresh')}
      </Button>
    </Grid>
  )
}
