import React from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { Outlet } from 'react-router-dom'

import Divider from '@mui/material/Divider'
import { HeaderSkeleton } from 'shared-components'
import { Header } from 'shared-components'
import { Footer } from 'shared-components'

import { locationData, store } from '../../cache'

import './style.css'

export const Layout = () => {
  return (
    <div className="layout-container">
      <React.Suspense fallback={<HeaderSkeleton />}>
        <Header store={store} locationData={locationData} />
      </React.Suspense>
      <div className="content-container">
        <Outlet />
      </div>
      <Divider sx={{ width: '100%' }} />
      <Footer />
    </div>
  )
}
