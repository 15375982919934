import React from 'react'

import PropTypes from 'prop-types'

import './style.css'

export const Divider = ({ variant }) => {
  return <hr className={variant} />
}

Divider.propTypes = {
  variant: PropTypes.string,
}
