import React from 'react'
import { useTranslation } from 'react-i18next'

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import './style.css'

export const ScrollHelper = () => {
  const { t } = useTranslation()
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <div className="scroll-helper" data-testid="scroll-helper">
      <IconButton
        aria-label="scroll to top"
        color="primary"
        data-testid="scroll-to-top-button"
        onClick={scrollToTop}
        size="large"
      >
        <ArrowUpwardIcon />
      </IconButton>
      <Typography
        className="scroll-text"
        data-testid="scroll-to-top-caption"
        variant="subtitle2"
      >
        {t('Scroll to Top')}
      </Typography>
    </div>
  )
}
