import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useMutation, useReactiveVar } from '@apollo/client'
import Backdrop from '@mui/material/Backdrop'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import Link from '@mui/material/Link'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import * as Sentry from '@sentry/browser'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { boolean, date, object, string } from 'yup'

import { PRIVACY_POLICY_URL, TERMS_AND_CONDITIONS_URL } from '../constants'
import { REQUEST_APPOINTMENT } from './requestMutation'

import './style.css'

export const RequestForm = ({
  alertType,
  locationData,
  message,
  patientData,
  store,
}) => {
  const [isBackdropOpen, setIsBackdropOpen] = useState(false)
  const [submitRequest, { loading }] = useMutation(REQUEST_APPOINTMENT)
  const { locationId, partnerId, providerId } = useReactiveVar(store)
  const location = useReactiveVar(locationData)
  const patient = useReactiveVar(patientData)
  const referrer = window.document.referrer
  const FIRST_AVAILABLE_VALUE = 'First available'
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  const resolvedLocale = i18n.resolvedLanguage
    ? i18n.resolvedLanguage.toUpperCase()
    : 'EN'

  const termsOfUseLabel = { inputProps: { 'aria-label': 'Terms of Use' } }

  const validationSchema = object({
    firstName: string().required(t('First name required')).max(50),
    lastName: string().required(t('Last name required')),
    dob: date()
      .max(
        new Date(new Date().getFullYear() - 1, 11, 31),
        t('Birthdate must be in the past')
      )
      .required(t('Birthdate required')),
    email: string().email().required(t('Email required')),
    phone: string().required(t('Mobile phone required')),
    provider: string().optional(),
    comment: string().max(500),
    termsOfUse: boolean().oneOf([true]).required(),
  })

  const isValidProviderId = useMemo(() => {
    if (!providerId) {
      return false
    }
    const providerExists =
      location &&
      location.providers &&
      location.providers.some(provider => provider.id === providerId)
    if (providerExists) {
      return true
    }
    Sentry.captureMessage(
      `Supplied provider ${providerId} does not exist for location ${locationId}`
    )
    return false
  }, [location, providerId])

  useEffect(() => {
    if (isValidProviderId) {
      formik.setFieldValue('provider', providerId)
    }
  }, [isValidProviderId])

  const formik = useFormik({
    initialValues: {
      firstName: (patient && patient.firstName) || '',
      lastName: (patient && patient.lastName) || '',
      dob: (patient && patient.dob) || '',
      email: (patient && patient.email) || '',
      phone: (patient && patient.phone) || '',
      provider: FIRST_AVAILABLE_VALUE,
      comment: (patient && patient.comment) || '',
      termsOfUse: false,
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      setIsBackdropOpen(true)
      patientData(values)
      const variables = {
        locationId,
        partnerId,
        patient: {
          firstName: values.firstName,
          lastName: values.lastName,
          dob: values.dob,
          email: values.email,
          phone: values.phone,
          comment: values.comment,
        },
        locale: resolvedLocale,
        referrer: referrer,
        ...(values.provider !== FIRST_AVAILABLE_VALUE && {
          providerId: values.provider,
        }),
      }
      try {
        const { data } = await submitRequest({ variables })
        if (data.requestAppointment && data.requestAppointment.id) {
          navigate('/request-confirmation')
        }
      } catch (error) {
        alertType('error')
        message(
          t(
            "Oops! Something didn't quite go as planned. Give it another try later and we'll get things sorted out."
          )
        )
        navigate('/')
      } finally {
        setIsBackdropOpen(false)
      }
    },
  })

  return (
    <form className="form" onSubmit={formik.handleSubmit}>
      <TextField
        aria-label="first-name"
        id="first-name"
        value={formik.values.firstName}
        name="firstName"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        label={t('First Name')}
        variant="outlined"
        disabled={formik.isSubmitting}
        InputProps={{
          inputProps: { maxLength: 50 },
        }}
        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
        helperText={formik.touched.firstName && formik.errors.firstName}
        required
        fullWidth
      />
      <TextField
        aria-label="last-name"
        id="last-name"
        value={formik.values.lastName}
        name="lastName"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
        helperText={formik.touched.lastName && formik.errors.lastName}
        disabled={formik.isSubmitting}
        label={t('Last Name')}
        variant="outlined"
        required
        fullWidth
      />
      <TextField
        aria-label="birthdate"
        id="date"
        data-testid="birthdate"
        value={formik.values.dob}
        name="dob"
        type="date"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        label={t('Birthdate')}
        variant="outlined"
        error={formik.touched.dob && Boolean(formik.errors.dob)}
        helperText={formik.touched.dob && formik.errors.dob}
        InputLabelProps={{ shrink: true }}
        disabled={formik.isSubmitting}
        required
        fullWidth
      />
      <TextField
        aria-label="email"
        id="email"
        value={formik.values.email}
        name="email"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        disabled={formik.isSubmitting}
        label={t('Email')}
        variant="outlined"
        required
        fullWidth
      />
      <TextField
        aria-label="phone"
        id="phone"
        value={formik.values.phone}
        name="phone"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        label={t('Mobile Phone')}
        variant="outlined"
        error={formik.touched.phone && Boolean(formik.errors.phone)}
        helperText={formik.touched.phone && formik.errors.phone}
        disabled={formik.isSubmitting}
        InputProps={{
          inputProps: { type: 'tel' },
        }}
        required
        fullWidth
      />
      <TextField
        aria-label="provider"
        id="provider"
        data-testid="provider-field"
        value={formik.values.provider}
        name="provider"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        label={t('Provider')}
        variant="outlined"
        disabled={formik.isSubmitting}
        inputProps={{ readOnly: isValidProviderId }}
        fullWidth
        select
      >
        <MenuItem value={FIRST_AVAILABLE_VALUE}>
          {t(FIRST_AVAILABLE_VALUE)}
        </MenuItem>
        {location &&
          location.providers &&
          location.providers.map((provider, index) => (
            <MenuItem key={index} value={provider.id}>
              {provider.name}
            </MenuItem>
          ))}
      </TextField>
      <TextField
        aria-label="comment"
        id="comment"
        value={formik.values.comment}
        name="comment"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        label={t('Comment')}
        variant="outlined"
        minRows={2}
        maxRows={4}
        fullWidth
        multiline
        error={formik.touched.comment && Boolean(formik.errors.comment)}
        disabled={formik.isSubmitting}
        helperText={
          formik.errors.comment
            ? formik.errors.comment
            : t(`Do not include any protected health information in the comment
        field. Additional details can be provided when contacted by the practice
        directly.`)
        }
        InputProps={{
          inputProps: { maxLength: 500 },
          sx: [
            {
              textarea: { marginBottom: theme => theme.spacing(2) },
            },
          ],
          endAdornment: (
            <InputAdornment
              disableTypography
              disablePointerEvents
              position="start"
              sx={[{ alignSelf: 'flex-end', position: 'absolute' }]}
            >
              {formik.values.comment.length} {t('characters')}
            </InputAdornment>
          ),
        }}
      />
      <Grid
        container
        display="flex"
        flexDirection="column"
        justifyContent="center"
        gap={1.5}
      >
        <Grid
          container
          item
          display="flex"
          flexDirection="row"
          alignItems="center"
          wrap="nowrap"
          gap={1}
        >
          <Checkbox
            {...termsOfUseLabel}
            name="termsOfUse"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            color="primary"
            required
          />
          <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
            I have read and accept Intelibly's{' '}
            <Link
              data-testid="form-terms-and-conditions"
              variant="caption"
              href={TERMS_AND_CONDITIONS_URL}
              target="_blank"
              rel="noopener"
            >
              Terms of Use
            </Link>{' '}
            and I consent to Intelibly collecting data, including sensitive
            information as fully described in the{' '}
            <Link
              data-testid="form-privacy-policy"
              variant="caption"
              href={PRIVACY_POLICY_URL}
              target="_blank"
              rel="noopener"
            >
              Privacy Policy
            </Link>
            .
          </Typography>
        </Grid>
        <Grid item>
          <Button
            aria-label="confirm"
            color="primary"
            disabled={formik.isSubmitting}
            disableElevation
            fullWidth
            type="submit"
            variant="contained"
          >
            {t('Confirm')}
          </Button>
        </Grid>
      </Grid>
      <Backdrop
        open={isBackdropOpen || loading}
        sx={{
          color: '#fff',
          opacity: 2,
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </form>
  )
}

RequestForm.propTypes = {
  alertType: PropTypes.func.isRequired,
  locationData: PropTypes.func.isRequired,
  message: PropTypes.func.isRequired,
  patientData: PropTypes.func.isRequired,
  store: PropTypes.func.isRequired,
}
