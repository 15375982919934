import { gql } from '@apollo/client'

export const REQUEST_APPOINTMENT = gql`
  mutation RequestAppointment(
    $locationId: ID!
    $patient: PatientInput!
    $partnerId: ID
    $providerId: ID
    $referrer: String!
    $locale: String
  ) {
    requestAppointment(
      locationId: $locationId
      patient: $patient
      partnerId: $partnerId
      providerId: $providerId
      referrer: $referrer
      locale: $locale
    ) {
      id
      insertedAt
      isEmergency
      locale
    }
  }
`
