import React from 'react'

export const Logo = () => {
  return (
    <svg width="100" viewBox="0 0 450 115" xmlns="http://www.w3.org/2000/svg">
      <title id="logoTitle">Intelibly Trademarked Logo</title>
      <g>
        <polygon
          fill="#1f3d7b"
          strokeWidth="0px"
          points="103.5,46.72999572753906 56.779998779296875,0 0,56.77998352050781 56.779998779296875,113.56999206542969 74.80999755859375,95.53001403808594 62.36000061035156,83.08000183105469 56.779998779296875,88.65998840332031 24.899993896484375,56.77998352050781 56.779998779296875,24.909988403320312 91.05001831054688,59.18000793457031 83.67001342773438,66.56001281738281 110.60000610351562,66.56001281738281 110.60000610351562,39.62998962402344 103.5,46.72999572753906 "
        />
        <polygon
          strokeWidth="0px"
          fill="#8f489a"
          points="157.69998168945312,56.87998962402344 100.92001342773438,0.0999908447265625 83.02999877929688,17.990005493164062 95.48001098632812,30.439987182617188 100.92001342773438,24.999984741210938 132.79998779296875,56.87998962402344 100.92001342773438,88.75999450683594 66.56999206542969,54.40998840332031 73.94999694824219,47.02998352050781 47.019989013671875,47.02998352050781 47.019989013671875,73.96000671386719 54.1199951171875,66.86000061035156 100.92001342773438,113.66001892089844 157.69998168945312,56.87998962402344 "
        />
      </g>
      <path
        fill="#231f20"
        strokeWidth="0px"
        d="m176.99,42.85l9.77,0l0,40.61l-9.77,0l0,-40.61zm0,-4.17l9.77,0l0,-9.82l-9.77,0l0,9.82zm145.25,0l9.77,0l0,-9.82l-9.77,0l0,9.82zm-106.49,3.87c-5.46,0 -8.69,1.23 -12,5.69l-0.15,0l0,-5.38l-9.23,0l0,40.61l9.77,0l0,-22.84c0,-4.92 2.85,-9.61 9,-9.61c4.38,0 6.31,3.46 6.31,7.62l0,24.84l9.77,0l0,-27.45c0,-8.31 -5.38,-13.46 -13.46,-13.46l0,-0.02l-0.01,0zm89.42,40.91l9.77,0l0,-54.6l-9.77,0l0,54.6zm17.07,0l9.77,0l0,-40.61l-9.77,0l0,40.61zm63,0l9.77,0l0,-54.6l-9.77,0l0,54.6zm46.36,-40.61l-10.33,24.69l-12.23,-24.69l-10.62,0l17.85,35.15l-8,18.38l9.61,0l24.06,-53.53l-10.35,0l0,0l0.01,0zm-182.56,-10.23l-9.77,0l0,11.15l-6,0l0,8.46l6,0l0,18.76c0,8.69 6,13.07 14.23,13.07c1.23,0 2.92,-0.08 3.85,-0.31l0,-8.64c-0.34,0 -0.68,0.03 -1,0.03c-5.69,0 -7.31,-2.46 -7.31,-6.23l0,-16.69l8.84,0l0,-8.46l-8.84,0l0,-11.15l0,0l0,0.01zm131.62,30.69c0,11.47 -9.3,20.76 -20.76,20.76s-20.76,-9.3 -20.76,-20.76l0,-0.15l0,0l0,-34.3l9.77,0l0,16.87c3.19,-2 6.95,-3.17 10.99,-3.17c11.47,0 20.76,9.3 20.76,20.76l0,0l0,-0.01zm-9.82,0c0,-6.04 -4.9,-10.94 -10.94,-10.94s-10.94,4.9 -10.94,10.94s4.9,10.94 10.94,10.94s10.94,-4.9 10.94,-10.94zm-79.03,5.77l9.65,0c-2.5,8.66 -10.47,14.99 -19.93,14.99c-11.47,0 -20.76,-9.3 -20.76,-20.76s9.3,-20.76 20.76,-20.76c8.45,0 15.71,5.06 18.95,12.3l-21.06,12.91l-6.31,3.87c2.15,2.17 5.12,3.52 8.42,3.52c4.43,0 8.25,-2.47 10.28,-6.07l0,0zm-22,-4.53l18.19,-11.15c-1.86,-1.22 -4.08,-1.94 -6.47,-1.94c-6.54,0 -11.84,5.3 -11.84,11.84c0,0.43 0.08,0.83 0.13,1.24l-0.01,0l0,0.01z"
      />
      <path
        fill="#231f20"
        strokeWidth="0px"
        d="m448.85,77.86c0,3.41 -2.76,6.17 -6.17,6.17s-6.17,-2.76 -6.17,-6.17s2.76,-6.17 6.17,-6.17s6.17,2.76 6.17,6.17zm-0.66,0c0,-3.09 -2.47,-5.59 -5.52,-5.59s-5.54,2.51 -5.54,5.59s2.47,5.59 5.54,5.59s5.52,-2.51 5.52,-5.59zm-3.7,0.97l1.52,2.18l-1.98,0l-1.21,-1.74l-0.65,0l0,1.74l-1.82,0l0,-6.31l2.71,0c1.35,0 2.39,0.9 2.39,2.15c0,0.87 -0.38,1.57 -0.95,1.98l0,0l-0.01,0zm-2.33,-1.09l0.58,0c0.53,0 0.87,-0.24 0.87,-0.68s-0.34,-0.7 -0.87,-0.7l-0.58,0l0,1.38z"
      />
    </svg>
  )
}
