import React from 'react'

import { Typography } from '@mui/material'
import PropTypes from 'prop-types'

import './style.css'

export const Subheader = ({ text, children, icon, variant }) => {
  const uppercase = variant == 'uppercase' ? 'uppercase' : ''

  return (
    <div className={`subheader ${uppercase}`}>
      {icon && (
        <div className="title-icon" aria-hidden="true">
          {icon}
        </div>
      )}
      {text && (
        <Typography
          align="center"
          sx={{
            color: 'var(--intelibly-branding-secondary-700)',
            textTransform: 'uppercase',
          }}
          variant="h5"
        >
          {text}
        </Typography>
      )}
      {children && { ...children }}
    </div>
  )
}

Subheader.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.node,
  variant: PropTypes.oneOf(['uppercase', '']),
  children: PropTypes.node,
}
