import React from 'react'
import { useTranslation } from 'react-i18next'

import { useReactiveVar } from '@apollo/client'
import { Typography } from '@mui/material'
import { RequestForm } from 'shared-components'
import { Analytics } from 'shared-components'
import { Subheader } from 'shared-components'

import {
  alertType,
  locationData,
  message,
  patientData,
  store,
} from '../../cache'

import './style.css'

export const RequestAppointment = () => {
  const { locationId, partnerId } = useReactiveVar(store)
  const { t } = useTranslation()

  React.useEffect(() => {
    Analytics.requestStarted({
      locationId,
      partnerId,
    })
  }, [locationId, partnerId])

  return (
    <div className="request-form-default">
      <div className="body">
        <div className="patient-form-section">
          <Subheader text={t('Patient Information')} />
          <Typography
            align="center"
            variant="body1"
            color={'var(--intelibly-branding-neutral-900)'}
          >
            {t(
              'This office is currently accepting appointment requests. An office representative will contact you to schedule a time and date.'
            )}
          </Typography>
          <RequestForm
            alertType={alertType}
            locationData={locationData}
            message={message}
            patientData={patientData}
            store={store}
          />
        </div>
      </div>
    </div>
  )
}
