import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { Divider } from '../Divider'
import { SubheaderSkeleton } from './SubheaderSkeleton'

import './style.css'

export const DateSelectionSkeleton = () => {
  return (
    <div className="date-selection-skeleton">
      <SubheaderSkeleton />
      <div className="calendar-skeleton">
        <div className="calendar-header-skeleton">
          <div className="month-year-skeleton">
            <Skeleton
              containerClassName="flex-1"
              containerTestId="month-year-skeleton"
              height={20}
            />
          </div>
        </div>
        <Divider variant="secondary" />
        <div className="today-button-skeleton">
          <Skeleton
            borderRadius={20}
            containerClassName="flex-1"
            containerTestId="today-button-skeleton"
            height={40}
          />
        </div>
        <div className="date-picker-skeleton">
          <div className="days-header-skeleton">
            <Skeleton
              containerClassName="flex-1"
              containerTestId="days-header-skeleton"
              height={14}
              style={{ marginBottom: '8px', marginTop: '8px' }}
            />
          </div>
          <div className="days-wrapper-skeleton">
            <Skeleton
              containerClassName="flex-1"
              containerTestId="days-wrapper-skeleton"
              count={5}
              height={40}
              style={{ marginBottom: '8px' }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
