if (!window.Countly || !Array.isArray(window.Countly.q)) {
  window.Countly = { q: [] }
}

// Define the event tracking function
function trackEvent(eventName, eventData) {
  if (window.Countly.q) {
    window.Countly.q.push([
      'add_event',
      { key: eventName, segmentation: eventData },
    ])
  }
}

export function firstAvailableSelected(eventData) {
  trackEvent('firstAvailableSelected', eventData)
}

export function todaySelected(eventData) {
  trackEvent('todaySelected', eventData)
}

export function nextMonthSelected(eventData) {
  trackEvent('nextMonthSelected', eventData)
}

export function previousMonthSelected(eventData) {
  trackEvent('previousMonthSelected', eventData)
}

export function dateSelected(eventData) {
  trackEvent('dateSelected', eventData)
}

export function timeSelected(eventData) {
  trackEvent('timeSelected', eventData)
}

export function bookingStarted(eventData) {
  trackEvent('bookingIntentStarted', eventData)
}

export function bookingCancelled(eventData) {
  trackEvent('bookingIntentCancelled', eventData)
}

export function bookingFormFieldFilled(eventData) {
  trackEvent('bookingFormFieldFilled', eventData)
}

export function bookingCompleted(eventData) {
  trackEvent('bookingIntentCompleted', eventData)
}

export function requestStarted(eventData) {
  trackEvent('requestIntentStarted', eventData)
}

export function requestCancelled(eventData) {
  trackEvent('requestIntentCancelled', eventData)
}

export function requestCompleted(eventData) {
  trackEvent('requestIntentCompleted', eventData)
}
