import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-locize-backend'

import { LOCIZE_API_KEY, LOCIZE_PROJECT_ID } from './constants'
import { isDev } from './utils/helpers'

// To avoid exposure, the API key should only be used in development, not in production.
const locizeOptions = {
  projectId: LOCIZE_PROJECT_ID,
  apiKey: LOCIZE_API_KEY,
  referenceLng: 'en',
}

i18n
  // i18next-locize-backend
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    debug: isDev,
    fallbackLng: 'en',
    load: 'languageOnly',
    saveMissing: isDev,
    updateMissing: isDev,
    backend: locizeOptions,
    supportedLngs: ['en', 'es', 'fr'],
  })

export default i18n
