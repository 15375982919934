import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useReactiveVar } from '@apollo/client'
import EditCalendarRoundedIcon from '@mui/icons-material/EditCalendarRounded'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'

import * as Analytics from '../analytics'

import './style.css'

export const TimezoneBlock = ({ store, timezoneAbbr }) => {
  const navigate = useNavigate()
  const timezone = useReactiveVar(timezoneAbbr)
  const { t } = useTranslation()
  const { locationId, partnerId } = useReactiveVar(store)

  const navigateToDatetimeSelect = () => {
    Analytics.bookingCancelled({
      locationId,
      partnerId,
    })
    navigate('/')
  }
  return (
    <div className="timezone-block">
      <Typography variant="subtitle2">
        {t('Appointment times are in')} {timezone}
      </Typography>
      <Button
        aria-label="edit date or time"
        color="primary"
        fullWidth
        startIcon={<EditCalendarRoundedIcon />}
        className="edit-appointment-button"
        onClick={navigateToDatetimeSelect}
        variant="outlined"
      >
        {t('Edit Date or Time')}
      </Button>
    </div>
  )
}
