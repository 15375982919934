import { gql } from '@apollo/client'

export const LOCATION = gql`
  query Location($id: ID!) {
    location(id: $id) {
      city
      id
      name
      phoneNumber
      state
      street
      timezone
      zip
      displayName
      practice {
        name
      }
      providers {
        id
        name
      }
    }
  }
`
