import * as Sentry from '@sentry/react'

import { APP_VERSION, SENTRY_DSN, SENTRY_ENVIRONMENT } from './constants'
import { isDev } from './utils/helpers'

/** Setup Sentry logging */
if (!isDev) {
  Sentry.init({
    environment: SENTRY_ENVIRONMENT,
    dsn: SENTRY_DSN,
    release: APP_VERSION,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
