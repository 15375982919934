import { ApolloClient, InMemoryCache, makeVar } from '@apollo/client'

import { API_URL } from './constants'

const cache = new InMemoryCache()

export const client = new ApolloClient({
  uri: API_URL,
  cache: cache,
})

export const store = makeVar(null)
export const alertType = makeVar(null)
export const message = makeVar(null)
export const locationData = makeVar(null)
export const patientData = makeVar(null)
export const timezoneAbbr = makeVar(null)
export const appointmentDateTime = makeVar(null)
export const availabilitySlotIdVar = makeVar(null)
export const appointmentsAvailable = makeVar(true)

export const resetAvailabilityCache = () => {
  return client.refetchQueries({
    updateCache(cache) {
      cache.evict({ fieldName: 'appointmentAvailability' })
    },
  })
}
