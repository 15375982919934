import React from 'react'
import { useTranslation } from 'react-i18next'

import { useReactiveVar } from '@apollo/client'
import { Typography } from '@mui/material'
import { Analytics } from 'shared-components'
import { Divider } from 'shared-components'
import { Subheader } from 'shared-components'

import { locationData, patientData, store } from '../../cache'

import './style.css'

export const RequestConfirmation = () => {
  const location = useReactiveVar(locationData)
  const patient = useReactiveVar(patientData)
  const { locationId, partnerId } = useReactiveVar(store)
  const { t } = useTranslation()

  React.useEffect(() => {
    Analytics.requestCompleted({
      locationId,
      partnerId,
    })
  }, [locationId, partnerId])

  const confirmedIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="80"
      viewBox="0 -960 960 960"
      width="80"
      fill="var(--intelibly-branding-primary-700)"
    >
      <path d="m438-327.384 147.078-147.077q8.922-8.923 21.499-9.115 12.577-.193 21.884 9.115 9.308 9.307 9.308 21.692 0 12.384-9.308 21.691L463.307-265.924Q452.461-255.078 438-255.078q-14.461 0-25.307-10.846l-81.154-81.154q-8.923-8.922-9.115-21.499-.193-12.577 9.115-21.884 9.307-9.308 21.692-9.308 12.384 0 21.691 9.308L438-327.384ZM212.309-100.001q-30.308 0-51.308-21t-21-51.308v-535.382q0-30.308 21-51.308t51.308-21h55.385v-53.847q0-13.153 8.808-21.961 8.807-8.807 21.961-8.807 13.153 0 21.961 8.807 8.807 8.808 8.807 21.961v53.847h303.076v-54.616q0-12.769 8.615-21.384t21.384-8.615q12.769 0 21.384 8.615 8.616 8.615 8.616 21.384v54.616h55.385q30.308 0 51.308 21t21 51.308v535.382q0 30.308-21 51.308t-51.308 21H212.309Zm0-59.999h535.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-375.382H200v375.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846Z" />
    </svg>
  )

  const nextStepsFooter = location => {
    if (location && location.practice.name) {
      return `${t(
        'You should receive a confirmation email shortly, followed by a contact from a member of'
      )} ${location.practice.name} ${t('to schedule the appointment.')}`
    } else {
      return t(
        'You should receive a confirmation email shortly, followed by a contact from a member of the dental practice to schedule the appointment.'
      )
    }
  }

  return (
    <div className="confirmation">
      <div className="body-2">
        <div className="confirmation-details">
          <Subheader text={t('Appointment Requested')} icon={confirmedIcon} />
          <Typography
            align="center"
            className="confirmationEmail"
            variant="body1"
          >
            {t('Confirmation email sent to:')}
            <br />
            {patient && patient.email}
          </Typography>
        </div>
        <Divider variant="secondary" />
        <div className="confirmation-details closing-details">
          <Typography
            align="center"
            className="next-steps"
            sx={{
              color: 'var(--intelibly-branding-neutral-800)',
            }}
            variant="subtitle2"
          >
            {nextStepsFooter(location)}
          </Typography>
        </div>
      </div>
    </div>
  )
}
