import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import './style.css'

export const SubheaderSkeleton = () => {
  return (
    <div className="subheader-skeleton">
      <h1>
        <Skeleton
          containerClassName="flex-1"
          containerTestId="subheader-skeleton"
          height={24}
        />
      </h1>
    </div>
  )
}
