import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import './style.css'

export const HeaderSkeleton = () => {
  return (
    <div className="header-skeleton">
      <div className="appointment-details-skeleton">
        <Skeleton
          containerClassName="flex-1"
          containerTestId="appointment-deatils-title-skeleton"
          count={1}
          height={22}
          style={{ marginBottom: '5px' }}
        />
        <Skeleton
          containerClassName="flex-1"
          containerTestId="appointment-deatils-body-skeleton"
          count={3}
          height={16}
          style={{ marginBottom: '5px' }}
        />
      </div>
    </div>
  )
}
