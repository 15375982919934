import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'

import './style.css'

export const RequestAppointmentButton = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const initiateRequestAppointment = () => {
    navigate('/request-appointment')
  }

  return (
    <div className="request-appointment-button">
      <div className="helper-text">
        <Typography align="center" variant="subtitle2">
          {t('Can’t find a time that works for you?')}
        </Typography>
      </div>
      <Button
        aria-label="request appointment"
        color="primary"
        data-testid="request-appointment-button"
        fullWidth
        onClick={initiateRequestAppointment}
        startIcon={<MoreTimeRoundedIcon />}
        variant="outlined"
      >
        {t('Request an appointment')}
      </Button>
    </div>
  )
}
